import {
  Keypair,
  PublicKey,
  SystemProgram,
  SYSVAR_CLOCK_PUBKEY,
  Transaction,
} from "@solana/web3.js";
import { StakingInstruction } from "./instruction";
import bs58 from "bs58";
import { readLittleInt64, readLittleInt32, readUInt8 } from "../readBinary";
import { AccountLayout, Token, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { signAndSendTransaction } from "../sendTransaction";
import { getTokenAccountMaxAmount } from "../getBalance";
import { getMintInfo } from "..";
import { getMemberData } from "./state";

const BN = require("bn.js");
const rateMul = 100000;
const defaultDecimals = 9;
const pubLen = 32;

// const connection = new Connection('http://localhost:8899', 'confirmed');

const programId = "BSkYedVTqDQiaBeih4q5j3Zkhb1qXGDFmDGMkdBipZfx";
export const farmingProgramId = programId;
const programAcc = new PublicKey(programId);
const nullKey = "11111111111111111111111111111111";
const nullAcc = new PublicKey(nullKey);
const usdcOracle = "Gnt27xtC473ZT2Mw5u8wZ68Z3gULkSTb5DuxJy7eJotD";
const oracleAcc = new PublicKey(usdcOracle);

// size
const registrarSize = 1 * 2 + 8 * 3 + 4 + 32 * 7;
const memberSize = 1 + 4 + 8 + 32 * 3;
const vendorSize = 1 + 4 + 8 * 4 + 32 * 3;

// seed
const seedPre = "0304";
export const registrarSeed = seedPre + "SypFmRegistrar";
export const memberSeed = seedPre + "SypFmMember";
export const vendorSeed = seedPre + "SypFmVendor";

export async function createRegistrar(
  connection,
  wallet,
  stakeMintKey,
  rewardMintKey,
  stakeRate,
  withdrawTimeLock
) {
  // use account
  let walletAcc = wallet.publicKey;
  let stakeMintAcc = new PublicKey(stakeMintKey);
  let rewardMintAcc = new PublicKey(rewardMintKey);
  // create registrar
  let nowTs = Math.round(new Date().getTime() / 1000);
  let seed = registrarSeed + nowTs.toString();
  let registrarAcc = await PublicKey.createWithSeed(
    walletAcc,
    seed,
    programAcc
  );
  // use data
  let [registrarPDA, nonce] = await PublicKey.findProgramAddress(
    [registrarAcc.toBuffer()],
    programAcc
  );
  // make transaction
  let tx = new Transaction();
  let registrarData = await connection.getAccountInfo(registrarAcc);
  if (registrarData != null) {
    return { code: 0, msg: "registrar exist" };
  }
  let lamports = await connection.getMinimumBalanceForRentExemption(
    registrarSize
  );
  tx.add(
    SystemProgram.createAccountWithSeed({
      fromPubkey: walletAcc,
      basePubkey: walletAcc,
      seed: seed,
      newAccountPubkey: registrarAcc,
      lamports,
      space: registrarSize,
      programId: programAcc,
    }),
    StakingInstruction.createInitInstruction(
      nonce,
      stakeRate * rateMul,
      withdrawTimeLock,
      registrarAcc,
      walletAcc,
      stakeMintAcc,
      oracleAcc,
      rewardMintAcc,
      oracleAcc,
      oracleAcc,
      programAcc
    )
  );
  let res = await signAndSendTransaction(connection, wallet, null, tx);
  if (res.code == 1) {
    return {
      code: 1,
      msg: "create registrar ok",
      signatrue: res.data,
      data: registrarAcc.toBase58(),
    };
  } else {
    return res;
  }
}

export async function updateRegistrar(
  connection,
  wallet,
  registrarKey,
  status
) {
  // use account
  let walletAcc = wallet.publicKey;
  let registrarAcc = new PublicKey(registrarKey);
  // make transaction
  let tx = new Transaction();
  tx.add(
    StakingInstruction.updateRegistrar(
      status,
      registrarAcc,
      walletAcc,
      programAcc
    )
  );
  let res = await signAndSendTransaction(connection, wallet, null, tx);
  if (res.code == 1) {
    return {
      code: 1,
      msg: "update registrar ok",
      signatrue: res.data,
      data: registrarAcc.toBase58(),
    };
  } else {
    return res;
  }
}

export async function getRegistrarData(connection, registrarKey) {
  // use account
  let registrarAcc = new PublicKey(registrarKey);
  let registrarData = await connection.getAccountInfo(registrarAcc);
  if (registrarData == null) {
    return;
  }
  let accData = registrarData.data;
  let nonce = readUInt8(accData.slice(0, 1));
  let status = readUInt8(accData.slice(1, 1 * 2));
  let stakeRate = readLittleInt64(accData.slice(1 * 2, 1 * 2 + 8));
  let withdrawalTimeLock = readLittleInt64(
    accData.slice(1 * 2 + 8, 1 * 2 + 8 * 2)
  );
  let totalStaked = readLittleInt64(
    accData.slice(1 * 2 + 8 * 2, 1 * 2 + 8 * 3)
  );
  let rewardEvent = readLittleInt32(
    accData.slice(1 * 2 + 8 * 3, 1 * 2 + 8 * 3 + 4)
  );
  let authority = bs58.encode(
    accData.slice(1 * 2 + 8 * 3 + 4, 1 * 2 + 8 * 3 + 4 + pubLen),
    "hex"
  );
  let rewardEventQ = bs58.encode(
    accData.slice(1 * 2 + 8 * 3 + 4 + pubLen, 1 * 2 + 8 * 3 + 4 + pubLen * 2),
    "hex"
  );
  let mint = bs58.encode(
    accData.slice(
      1 * 2 + 8 * 3 + 4 + pubLen * 2,
      1 * 2 + 8 * 3 + 4 + pubLen * 3
    ),
    "hex"
  );
  let mintOracle = bs58.encode(
    accData.slice(
      1 * 2 + 8 * 3 + 4 + pubLen * 3,
      1 * 2 + 8 * 3 + 4 + pubLen * 4
    ),
    "hex"
  );
  let rewardMint = bs58.encode(
    accData.slice(
      1 * 2 + 8 * 3 + 4 + pubLen * 4,
      1 * 2 + 8 * 3 + 4 + pubLen * 5
    ),
    "hex"
  );
  let rewardMintOracle = bs58.encode(
    accData.slice(
      1 * 2 + 8 * 3 + 4 + pubLen * 5,
      1 * 2 + 8 * 3 + 4 + pubLen * 6
    ),
    "hex"
  );
  let sypMintOracle = bs58.encode(
    accData.slice(
      1 * 2 + 8 * 3 + 4 + pubLen * 6,
      1 * 2 + 8 * 3 + 4 + pubLen * 7
    ),
    "hex"
  );
  let decimals = 9;
  {
    let res = await connection.getTokenSupply(new PublicKey(mint));
    if (res.value) decimals = res.value.decimals;
  }
  totalStaked /= 10 ** decimals;
  return {
    registrarKey,
    nonce,
    status,
    stakeRate,
    withdrawalTimeLock,
    totalStaked,
    rewardEvent,
    authority,
    rewardEventQ,
    mint,
    mintOracle,
    rewardMint,
    rewardMintOracle,
    sypMintOracle,
  };
}

export async function createMember(connection, wallet, registrarKey) {
  // use account
  let walletAcc = wallet.publicKey;
  let registrarAcc = new PublicKey(registrarKey);
  // create member
  let nowTs = Math.round(new Date().getTime() / 1000);
  let seed = memberSeed + nowTs.toString();
  let memberAcc = await PublicKey.createWithSeed(walletAcc, seed, programAcc);
  let memberData = await connection.getAccountInfo(memberAcc);
  // use data
  let lamportsM = await connection.getMinimumBalanceForRentExemption(
    memberSize
  );
  let lamportsV = await connection.getMinimumBalanceForRentExemption(
    AccountLayout.span
  );
  let registrarData = await getRegistrarData(connection, registrarKey);
  let [memberPDA, nonce] = await PublicKey.findProgramAddress(
    [registrarAcc.toBuffer(), memberAcc.toBuffer()],
    programAcc
  );
  let memberVaultAccount = Keypair.generate();
  // make transaction
  let tx = new Transaction();
  if (memberData != null) {
    return { code: 0, msg: "member exist" };
  }
  tx.add(
    SystemProgram.createAccountWithSeed({
      fromPubkey: walletAcc,
      basePubkey: walletAcc,
      seed: seed,
      newAccountPubkey: memberAcc,
      lamports: lamportsM,
      space: memberSize,
      programId: programAcc,
    }),
    SystemProgram.createAccount({
      fromPubkey: walletAcc,
      newAccountPubkey: memberVaultAccount.publicKey,
      lamports: lamportsV,
      space: AccountLayout.span,
      programId: TOKEN_PROGRAM_ID,
    }),
    Token.createInitAccountInstruction(
      TOKEN_PROGRAM_ID,
      new PublicKey(registrarData.mint),
      memberVaultAccount.publicKey,
      memberPDA
    ),
    StakingInstruction.createMember(
      nonce,
      registrarAcc,
      memberAcc,
      walletAcc,
      memberVaultAccount.publicKey,
      TOKEN_PROGRAM_ID,
      memberPDA,
      programAcc
    )
  );
  let res = await signAndSendTransaction(
    connection,
    wallet,
    [memberVaultAccount],
    tx
  );
  if (res.code == 1) {
    return {
      code: 1,
      msg: "create member ok",
      signatrue: res.data,
      data: memberAcc.toBase58(),
    };
  } else {
    return res;
  }
}

export async function getMemberData2(connection, memberKey) {
  // use account
  let memberAcc = new PublicKey(memberKey);
  let memberData = await connection.getAccountInfo(memberAcc);
  if (memberData == null) {
    return;
  }
  let accData = memberData.data;
  console.log("member data", accData);
  let nonce = readUInt8(accData.slice(0, 1));
  let rewardCursor = readLittleInt32(accData.slice(1, 1 + 4));
  let lastStakeTs = readLittleInt64(accData.slice(1 + 4, 1 + 4 + 8));
  let registrar = bs58.encode(
    accData.slice(1 + 4 + 8, 1 + 4 + 8 + pubLen),
    "hex"
  );
  let beneficiary = bs58.encode(
    accData.slice(1 + 4 + 8 + pubLen, 1 + 4 + 8 + pubLen * 2),
    "hex"
  );
  let balance_vault = bs58.encode(
    accData.slice(1 + 4 + 8 + pubLen * 2, 1 + 4 + 8 + pubLen * 3),
    "hex"
  );
  return {
    nonce,
    rewardCursor,
    lastStakeTs,
    registrar,
    beneficiary,
    balance_vault,
  };
}

export async function getMemberVault(connection, memberKey) {
  let memberData = await getMemberData(connection, memberKey);
  if (memberData) {
    if (memberData.balance_vault != nullKey) {
      let vaultAcc = new PublicKey(memberData.balance_vault);
      let res = await connection.getTokenAccountBalance(vaultAcc);
      if (res != undefined) {
        let balance = {
          acc: memberData.spt,
          amount: parseFloat(res.value.uiAmount),
          decimal: parseFloat(res.value.decimals),
        };
        return balance;
      } else {
        let balance = { acc: memberData.spt, amount: 0, decimal: 9 };
        return balance;
      }
    }
  } else {
    let balance = { acc: memberData.spt, amount: 0, decimal: 9 };
    return balance;
  }
}

export async function dropReward(connection, wallet, registrarKey, amount) {
  // use account
  let walletAcc = wallet.publicKey;
  let registrarAcc = new PublicKey(registrarKey);
  // create vendor
  let nowTs = Math.round(new Date().getTime() / 1000);
  let seed = vendorSeed + nowTs.toString();
  let vendorAcc = await PublicKey.createWithSeed(walletAcc, seed, programAcc);
  // use data
  let lamportsVd = await connection.getMinimumBalanceForRentExemption(
    vendorSize
  );
  let registrarData = await getRegistrarData(connection, registrarKey);
  let [vendorPDA, nonce] = await PublicKey.findProgramAddress(
    [registrarAcc.toBuffer(), vendorAcc.toBuffer()],
    programAcc
  );
  let userTokenAcc;
  {
    let res = await getTokenAccountMaxAmount(
      connection,
      wallet,
      registrarData.rewardMint
    );
    if (res.code == 1) {
      userTokenAcc = res.data.acc;
    } else {
      return res;
    }
  }
  let vendorVaultAccount = Keypair.generate();
  let lamportsV = await connection.getMinimumBalanceForRentExemption(
    AccountLayout.span
  );
  let decimals = defaultDecimals;
  {
    let res = await getMintInfo(connection, registrarData.mint);
    if (res.code == 1) {
      decimals = res.data.decimals;
    }
  }
  // make transaction
  let tx = new Transaction();
  let vendorData = await connection.getAccountInfo(vendorAcc);
  if (vendorData != null) {
    return { code: 0, msg: "vendor exist" };
  }
  tx.add(
    SystemProgram.createAccountWithSeed({
      fromPubkey: walletAcc,
      basePubkey: walletAcc,
      seed: seed,
      newAccountPubkey: vendorAcc,
      lamports: lamportsVd,
      space: vendorSize,
      programId: programAcc,
    }),
    SystemProgram.createAccount({
      fromPubkey: walletAcc,
      newAccountPubkey: vendorVaultAccount.publicKey,
      lamports: lamportsV,
      space: AccountLayout.span,
      programId: TOKEN_PROGRAM_ID,
    }),
    Token.createInitAccountInstruction(
      TOKEN_PROGRAM_ID,
      new PublicKey(registrarData.rewardMint),
      vendorVaultAccount.publicKey,
      vendorPDA
    ),
    StakingInstruction.dropReward(
      amount * 10 ** decimals,
      nonce,
      registrarAcc,
      vendorAcc,
      vendorPDA,
      vendorVaultAccount.publicKey,
      userTokenAcc,
      walletAcc,
      SYSVAR_CLOCK_PUBKEY,
      TOKEN_PROGRAM_ID,
      programAcc
    )
  );
  let res = await signAndSendTransaction(
    connection,
    wallet,
    [vendorVaultAccount],
    tx
  );
  if (res.code == 1) {
    return {
      code: 1,
      msg: "drop reward ok",
      signatrue: res.data,
      data: vendorAcc.toBase58(),
    };
  } else {
    return res;
  }
}

export async function claimReward(connection, wallet, memberKey) {
  // use account
  let walletAcc = wallet.publicKey;
  let memberAcc = new PublicKey(memberKey);
  let memberData = await getMemberData(connection, memberKey);
  console.log("member data", memberData);
  let registrarAcc = new PublicKey(memberData.registrar);
  let registrarData = await getRegistrarData(connection, memberData.registrar);
  let userTokenAcc;
  {
    let res = await getTokenAccountMaxAmount(
      connection,
      wallet,
      registrarData.rewardMint
    );
    if (res.code == 1) {
      userTokenAcc = res.data.acc;
    } else {
      return res;
    }
  }
  // make transaction
  let tx = new Transaction();
  let rewardLength = registrarData.rewardEvent - memberData.rewardCursor;
  if (rewardLength > 3) rewardLength = 3;
  if (rewardLength) {
    for (let i = 0; i < rewardLength; i++) {
      let rewardEvent = memberData.rewardCursor + i;
      let vendorAcc = await findRewardVendor(
        connection,
        memberData.registrar,
        rewardEvent
      );
      if (vendorAcc) {
        let vendorData = await getVendorData(connection, vendorAcc);
        console.log("vendor data", vendorData);
        // use data
        let [vendorPDA, nonce] = await PublicKey.findProgramAddress(
          [registrarAcc.toBuffer(), vendorAcc.toBuffer()],
          programAcc
        );
        console.log(
          "member",
          memberData.rewardCursor,
          "vendor",
          vendorData.rewardEvent
        );
        tx.add(
          StakingInstruction.claimReward(
            registrarAcc,
            memberAcc,
            walletAcc,
            new PublicKey(memberData.balance_vault),
            vendorAcc,
            vendorPDA,
            new PublicKey(vendorData.vault),
            userTokenAcc,
            SYSVAR_CLOCK_PUBKEY,
            TOKEN_PROGRAM_ID,
            programAcc
          )
        );
      }
    }
    let res = await signAndSendTransaction(connection, wallet, null, tx);
    if (res.code == 1) {
      return {
        code: 1,
        msg: "claim reward ok",
        signatrue: res.data,
        data: res.data,
      };
    } else {
      return res;
    }
  } else {
    return { code: -3, msg: "no reward", signatrue: "", data: "" };
  }
}

export async function getVendorData(connection, vendorAcc) {
  // use account
  let vendorData = await connection.getAccountInfo(vendorAcc);
  if (vendorData == null) {
    console.log("vendor not exist");
    return;
  }
  let accData = vendorData.data;
  let nonce = readUInt8(accData.slice(0, 1));
  let rewardEvent = readLittleInt32(accData.slice(1, 1 + 4));
  let start_ts = readLittleInt64(accData.slice(1 + 4, 1 + 4 + 8));
  let expiry_ts = readLittleInt64(accData.slice(1 + 4 + 8, 1 + 4 + 8 * 2));
  let total_reward = readLittleInt64(
    accData.slice(1 + 4 + 8 * 2, 1 + 4 + 8 * 3)
  );
  let total_staked = readLittleInt64(
    accData.slice(1 + 4 + 8 * 3, 1 + 4 + 8 * 4)
  );
  let registrar = bs58.encode(
    accData.slice(1 + 4 + 8 * 4, 1 + 4 + 8 * 4 + pubLen),
    "hex"
  );
  let vault = bs58.encode(
    accData.slice(1 + 4 + 8 * 4 + pubLen, 1 + 4 + 8 * 4 + pubLen * 2),
    "hex"
  );
  let manager = bs58.encode(
    accData.slice(1 + 4 + 8 * 4 + pubLen * 2, 1 + 4 + 8 * 4 + pubLen * 3),
    "hex"
  );
  return {
    nonce,
    rewardEvent,
    start_ts,
    expiry_ts,
    total_reward,
    total_staked,
    registrar,
    vault,
    manager,
  };
}

export async function directStake(connection, wallet, memberKey, amount) {
  // use account
  let walletAcc = wallet.publicKey;
  let memberAcc = new PublicKey(memberKey);
  let memberData = await getMemberData(connection, memberKey);
  let registrarAcc = new PublicKey(memberData.registrar);
  let registrarData = await getRegistrarData(connection, memberData.registrar);
  // use data
  let userTokenAcc;
  {
    let res = await getTokenAccountMaxAmount(
      connection,
      wallet,
      registrarData.mint
    );
    if (res.code == 1) {
      userTokenAcc = res.data.acc;
    } else {
      return res;
    }
  }
  let decimals = defaultDecimals;
  {
    let res = await getMintInfo(connection, registrarData.mint);
    if (res.code == 1) {
      decimals = res.data.decimals;
    }
  }
  // make transaction
  let tx = new Transaction();
  tx.add(
    StakingInstruction.directStake(
      amount * 10 ** decimals,
      registrarAcc,
      memberAcc,
      walletAcc,
      new PublicKey(memberData.balance_vault),
      userTokenAcc,
      SYSVAR_CLOCK_PUBKEY,
      TOKEN_PROGRAM_ID,
      programAcc
    )
  );
  let res = await signAndSendTransaction(connection, wallet, null, tx);
  if (res.code == 1) {
    return {
      code: 1,
      msg: "direct stake ok",
      signatrue: res.data,
      data: memberKey,
    };
  } else {
    return res;
  }
}

export async function directUnstake(connection, wallet, memberKey, amount) {
  // use account
  let walletAcc = wallet.publicKey;
  let memberAcc = new PublicKey(memberKey);
  let memberData = await getMemberData(connection, memberKey);
  let registrarAcc = new PublicKey(memberData.registrar);
  let registrarData = await getRegistrarData(connection, memberData.registrar);
  // use data
  let userTokenAcc;
  {
    let res = await getTokenAccountMaxAmount(
      connection,
      wallet,
      registrarData.mint
    );
    if (res.code == 1) {
      userTokenAcc = res.data.acc;
    } else {
      return res;
    }
  }
  let decimals = defaultDecimals;
  {
    let res = await getMintInfo(connection, registrarData.mint);
    if (res.code == 1) {
      decimals = res.data.decimals;
    }
  }
  let [memberPDA, nonce] = await PublicKey.findProgramAddress(
    [registrarAcc.toBuffer(), memberAcc.toBuffer()],
    programAcc
  );
  // make transaction
  let tx = new Transaction();
  tx.add(
    StakingInstruction.directUnstake(
      amount * 10 ** decimals,
      registrarAcc,
      memberAcc,
      memberPDA,
      walletAcc,
      new PublicKey(memberData.balance_vault),
      userTokenAcc,
      TOKEN_PROGRAM_ID,
      programAcc
    )
  );
  let res = await signAndSendTransaction(connection, wallet, null, tx);
  if (res.code == 1) {
    return {
      code: 1,
      msg: "direct unstake ok",
      signatrue: res.data,
      data: memberKey,
    };
  } else {
    return res;
  }
}

export async function findRegistrar(connection) {
  let res = await connection.getProgramAccounts(programAcc, {
    filters: [{ dataSize: registrarSize }],
  });
  if (res.length > 0) {
    return res.map((e) => {
      return e.pubkey;
    });
  } else {
    return [];
  }
}

export async function findMember(connection, wallet) {
  let walletAcc = wallet.publicKey;
  let res = await connection.getProgramAccounts(programAcc, {
    filters: [
      {
        memcmp: {
          offset: 1 + 4 + 8 + 32,
          bytes: walletAcc.toBase58(),
        },
      },
      { dataSize: memberSize },
    ],
  });
  if (res.length > 0) {
    return res.map((e) => {
      return e.pubkey;
    });
  } else {
    return [];
  }
}

export async function findMemberKeyByBeneficiary(connection, userWalletKey) {
  let res = await connection.getProgramAccounts(programAcc, {
    filters: [
      {
        memcmp: {
          offset: 1 + 4 + 8 + 32,
          bytes: userWalletKey,
        },
      },
      { dataSize: memberSize },
    ],
  });
  if (res.length > 0) {
    return res.map((e) => {
      return e.pubkey;
    });
  } else {
    return [];
  }
}

export async function findMember2(connection, wallet, registrarKey) {
  let walletAcc = wallet.publicKey;
  let res = await connection.getProgramAccounts(programAcc, {
    filters: [
      {
        memcmp: {
          offset: 1 + 4 + 8,
          bytes: registrarKey,
        },
      },
      {
        memcmp: {
          offset: 1 + 4 + 8 + 32,
          bytes: walletAcc.toBase58(),
        },
      },
      { dataSize: memberSize },
    ],
  });
  if (res.length > 0) {
    return res[0].pubkey;
  } else {
    return;
  }
}

export async function findMember3(connection, registrarKey) {
  let res = await connection.getProgramAccounts(programAcc, {
    filters: [
      {
        memcmp: {
          offset: 1 + 4 + 8,
          bytes: registrarKey,
        },
      },
      { dataSize: memberSize },
    ],
  });
  if (res.length > 0) {
    return res.map((e) => {
      return e.pubkey;
    });
  } else {
    return [];
  }
}

export async function findRewardVendor(connection, registrarKey, cursor) {
  let data = new BN(cursor).toArray("le", 4);
  let res = await connection.getProgramAccounts(programAcc, {
    filters: [
      {
        memcmp: {
          offset: 1,
          bytes: bs58.encode(data, "hex"),
        },
      },
      {
        memcmp: {
          offset: 1 + 4 + 8 * 4,
          bytes: registrarKey,
        },
      },
      { dataSize: vendorSize },
    ],
  });
  if (res.length > 0) {
    return res[0].pubkey;
  } else {
    return;
  }
}

export async function findRewardVendor2(connection, registrarKey) {
  let res = await connection.getProgramAccounts(programAcc, {
    filters: [
      {
        memcmp: {
          offset: 1 + 4 + 8 * 4,
          bytes: registrarKey,
        },
      },
      { dataSize: vendorSize },
    ],
  });
  if (res.length > 0) {
    return res.map((e) => {
      return e.pubkey;
    });
  } else {
    return;
  }
}

export async function getBalance(connection, wallet, registrarKey) {
  // use data
  let registrarData = await getRegistrarData(connection, registrarKey);
  let userTokenAcc;
  {
    let res = await getTokenAccountMaxAmount(
      connection,
      wallet,
      registrarData.mint
    );
    if (res.code == 1) {
      userTokenAcc = res.data.acc;
    } else {
      return res;
    }
  }
  {
    let res = await connection.getTokenAccountBalance(userTokenAcc);
    if (res != undefined) {
      return { code: 1, msg: "get balance ok", data: res.value.uiAmount };
    } else {
      return { code: 0, msg: "get balance fail", data: 0.0 };
    }
  }
}

export async function getReward(connection, wallet, registrarKey) {
  // use account
  // let walletAcc = wallet.publicKey;
  let registrarData = await getRegistrarData(connection, registrarKey);
  let stakeMintData;
  {
    let res = await getMintInfo(connection, registrarData.mint);
    if (res.code == 1) {
      stakeMintData = res.data;
    }
  }
  let rewardMintData;
  {
    let res = await getMintInfo(connection, registrarData.rewardMint);
    if (res.code == 1) {
      rewardMintData = res.data;
    }
  }
  let memberAcc;
  {
    let res = await findMember2(connection, wallet, registrarKey);
    if (res) {
      memberAcc = res;
    } else {
      return { code: -11, msg: "user has no member" };
    }
  }
  let memberData = await getMemberData(connection, memberAcc.toBase58());
  let memberVault = await getMemberVault(connection, memberAcc.toBase58());
  if (memberData.rewardCursor > registrarData.rewardEvent) {
    return { code: -2, msg: "there is no reward" };
  }
  // find vendor
  let vendorList = [];
  for (let i = memberData.rewardCursor; i <= registrarData.rewardEvent; i++) {
    let res = await findRewardVendor(connection, registrarKey, i);
    if (res) {
      let vendorAcc = res;
      let vendorData = await getVendorData(connection, vendorAcc);
      vendorList.push({ acc: vendorAcc, data: vendorData });
    }
  }
  // calculate reward
  let totalReward = 0;
  for (let i = 0; i < vendorList.length; i++) {
    let vendorData = vendorList[i].data;
    let totalStaked = 0;
    if (vendorData.rewardEvent <= registrarData.rewardEvent) {
      totalStaked = vendorData.total_staked / 10 ** stakeMintData.decimals;
    } else if (vendorData.rewardEvent == registrarData.rewardEvent) {
      totalStaked = registrarData.totalStaked / 10 ** stakeMintData.decimals;
    }
    let rewardTemp = vendorData.total_reward / 10 ** rewardMintData.decimals;
    let rewardAmount = (rewardTemp / totalStaked) * memberVault.amount;
    console.log(
      "total reward",
      rewardTemp,
      "total staked",
      totalStaked,
      "user staked",
      memberVault.amount,
      "user reward",
      rewardAmount
    );
    totalReward += rewardAmount;
  }
  return { code: 1, msg: "find reward ok", data: totalReward };
}
