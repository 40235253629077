/**
 * @flow
 */

const { TransactionInstruction } = require("@solana/web3.js");
const BufferLayout = require("buffer-layout");
const BN = require("bn.js");
export class StakingInstruction {
  static createInitInstruction(
    nonce,
    stake_rate,
    withdrawl_timelock,
    registrar_acc,
    authority_acc,
    mint_acc,
    mint_oracle,
    reward_mint_acc,
    reward_mint_oracle,
    syp_mint_oracle,
    programId
  ) {
    console.log("staking instruction create init instrucetion");
    console.log(
      "nonce",
      nonce,
      "stake rate",
      stake_rate,
      "lock time",
      withdrawl_timelock,
      "registrar",
      registrar_acc.toBase58(),
      "authority",
      authority_acc.toBase58(),
      "mint",
      mint_acc.toBase58(),
      "mint oracle",
      mint_oracle.toBase58(),
      "reward mint",
      reward_mint_acc.toBase58(),
      "reward mint oracle",
      reward_mint_oracle.toBase58(),
      "syp mint oracle",
      syp_mint_oracle.toBase58(),
      "program id",
      programId.toBase58()
    );
    const dataLayout = BufferLayout.struct([
      BufferLayout.u8("i"),
      BufferLayout.blob(1, "nonce"),
      BufferLayout.blob(8, "stake_rate"),
      BufferLayout.blob(8, "withdrawl_timelock"),
    ]);

    const data = Buffer.alloc(dataLayout.span);
    // token list , a buff and loop
    dataLayout.encode(
      {
        i: 0,
        nonce: Buffer.from(new BN(nonce).toArray("le", 1)),
        stake_rate: Buffer.from(new BN(stake_rate).toArray("le", 8)),
        withdrawl_timelock: Buffer.from(
          new BN(withdrawl_timelock).toArray("le", 8)
        ),
      },
      data
    );

    let keys = [
      { pubkey: registrar_acc, isSigner: false, isWritable: true },
      { pubkey: authority_acc, isSigner: true, isWritable: false },
      { pubkey: mint_acc, isSigner: false, isWritable: false },
      { pubkey: mint_oracle, isSigner: false, isWritable: false },
      { pubkey: reward_mint_acc, isSigner: false, isWritable: false },
      { pubkey: reward_mint_oracle, isSigner: false, isWritable: false },
      { pubkey: syp_mint_oracle, isSigner: false, isWritable: false },
    ];

    const trxi = new TransactionInstruction({ keys, programId, data });
    return trxi;
  }

  static updateRegistrar(status, registrar_acc, authority, programId) {
    console.log(
      "update registrar",
      "status",
      status,
      "registrar",
      registrar_acc.toBase58(),
      "authority",
      authority.toBase58(),
      "program",
      programId.toBase58()
    );
    const dataLayout = BufferLayout.struct([
      BufferLayout.u8("i"),
      BufferLayout.blob(1, "status"),
    ]);

    const data = Buffer.alloc(dataLayout.span);
    dataLayout.encode(
      {
        i: 1,
        status: Buffer.from(new BN(status).toArray("le", 1)),
      },
      data
    );

    let keys = [
      { pubkey: registrar_acc, isSigner: false, isWritable: true },
      { pubkey: authority, isSigner: true, isWritable: false },
    ];

    const trxi = new TransactionInstruction({ keys, programId, data });
    return trxi;
  }

  static createMember(
    nonce,
    registrar_acc,
    member_acc,
    beneficiary_acc,
    balance_vault_acc,
    token_program_acc,
    member_pda_acc,
    programId
  ) {
    console.log(
      "create member",
      "nonce",
      nonce,
      "member",
      member_acc.toBase58(),
      "registrar",
      registrar_acc.toBase58(),
      "beneficiary",
      beneficiary_acc.toBase58(),
      "vault",
      balance_vault_acc.toBase58(),
      "token program",
      token_program_acc.toBase58(),
      "member pda",
      member_pda_acc.toBase58(),
      "program id",
      programId.toBase58()
    );
    const dataLayout = BufferLayout.struct([
      BufferLayout.u8("i"),
      BufferLayout.blob(1, "nonce"),
    ]);
    const data = Buffer.alloc(dataLayout.span);
    // token list , a buff and loop
    dataLayout.encode(
      { i: 2, nonce: Buffer.from(new BN(nonce).toArray("le", 1)) },
      data
    );
    let keys = [
      { pubkey: registrar_acc, isSigner: false, isWritable: false },
      { pubkey: member_acc, isSigner: false, isWritable: true },
      { pubkey: beneficiary_acc, isSigner: true, isWritable: false },
      { pubkey: balance_vault_acc, isSigner: false, isWritable: false },
      { pubkey: token_program_acc, isSigner: false, isWritable: false },
      { pubkey: member_pda_acc, isSigner: false, isWritable: false },
    ];
    const trxi = new TransactionInstruction({ keys, programId, data });
    return trxi;
  }

  static directStake(
    amount,
    registrar_acc,
    member_acc,
    beneficiary_acc,
    balance_vault_acc,
    from_acc,
    clock_acc,
    token_program_acc,
    programId
  ) {
    console.log(
      "direct stake",
      "amount",
      amount,
      "registrar",
      registrar_acc.toBase58(),
      "member",
      member_acc.toBase58(),
      "beneficiary",
      beneficiary_acc.toBase58(),
      "balance vault",
      balance_vault_acc.toBase58(),
      "from",
      from_acc.toBase58(),
      "clock",
      clock_acc.toBase58(),
      "token program",
      token_program_acc.toBase58(),
      "program id",
      programId.toBase58()
    );
    const dataLayout = BufferLayout.struct([
      BufferLayout.u8("i"),
      BufferLayout.blob(8, "amount"),
    ]);
    const data = Buffer.alloc(dataLayout.span);
    // token list , a buff and loop
    dataLayout.encode(
      {
        i: 12,
        amount: Buffer.from(new BN(amount).toArray("le", 8)),
      },
      data
    );
    let keys = [
      { pubkey: registrar_acc, isSigner: false, isWritable: true },
      { pubkey: member_acc, isSigner: false, isWritable: true },
      { pubkey: beneficiary_acc, isSigner: true, isWritable: false },
      { pubkey: balance_vault_acc, isSigner: false, isWritable: true },
      { pubkey: from_acc, isSigner: false, isWritable: true },
      { pubkey: clock_acc, isSigner: false, isWritable: false },
      { pubkey: token_program_acc, isSigner: false, isWritable: false },
    ];
    const trxi = new TransactionInstruction({ keys, programId, data });
    return trxi;
  }

  static directUnstake(
    amount,
    registrar_acc,
    member_acc,
    member_pda_acc,
    beneficiary_acc,
    balance_vault_acc,
    to_acc,
    token_program_acc,
    programId
  ) {
    console.log(
      "direct unstake",
      "amount",
      amount,
      "registrar",
      registrar_acc.toBase58(),
      "member",
      member_acc.toBase58(),
      "member pda",
      member_pda_acc.toBase58(),
      "beneficiary",
      beneficiary_acc.toBase58(),
      "balance vault",
      balance_vault_acc.toBase58(),
      "to",
      to_acc.toBase58(),
      "token program",
      token_program_acc.toBase58(),
      "program id",
      programId.toBase58()
    );
    const dataLayout = BufferLayout.struct([
      BufferLayout.u8("i"),
      BufferLayout.blob(8, "amount"),
    ]);
    const data = Buffer.alloc(dataLayout.span);
    // token list , a buff and loop
    dataLayout.encode(
      {
        i: 13,
        amount: Buffer.from(new BN(amount).toArray("le", 8)),
      },
      data
    );
    let keys = [
      { pubkey: registrar_acc, isSigner: false, isWritable: true },
      { pubkey: member_acc, isSigner: false, isWritable: false },
      { pubkey: member_pda_acc, isSigner: false, isWritable: false },
      { pubkey: beneficiary_acc, isSigner: true, isWritable: false },
      { pubkey: balance_vault_acc, isSigner: false, isWritable: true },
      { pubkey: to_acc, isSigner: false, isWritable: true },
      { pubkey: token_program_acc, isSigner: false, isWritable: false },
    ];
    const trxi = new TransactionInstruction({
      keys,
      programId: programId,
      data,
    });
    return trxi;
  }

  static dropReward(
    amount,
    nonce,
    registrar_acc,
    vendor_acc,
    vendor_pda_acc,
    vendor_vault_acc,
    from_acc,
    manager_acc,
    clock_acc,
    token_program_acc,
    programId
  ) {
    console.log("staking instruction drop reward");
    console.log(
      "amount",
      amount,
      "nonce",
      nonce,
      "registrar",
      registrar_acc.toBase58(),
      "vendor",
      vendor_acc.toBase58(),
      "vendor pda",
      vendor_pda_acc.toBase58(),
      "vendor vault",
      vendor_vault_acc.toBase58(),
      "from",
      from_acc.toBase58(),
      "manager",
      manager_acc.toBase58(),
      "clock",
      clock_acc.toBase58(),
      "token program",
      token_program_acc.toBase58(),
      "program id",
      programId.toBase58()
    );
    const dataLayout = BufferLayout.struct([
      BufferLayout.u8("i"),
      BufferLayout.blob(8, "amount"),
      BufferLayout.blob(1, "nonce"),
    ]);
    const data = Buffer.alloc(dataLayout.span);
    // token list , a buff and loop
    dataLayout.encode(
      {
        i: 9,
        amount: Buffer.from(new BN(amount).toArray("le", 8)),
        nonce: Buffer.from(new BN(nonce).toArray("le", 1)),
      },
      data
    );
    let keys = [
      { pubkey: registrar_acc, isSigner: false, isWritable: true },
      { pubkey: vendor_acc, isSigner: false, isWritable: true },
      { pubkey: vendor_pda_acc, isSigner: false, isWritable: false },
      { pubkey: vendor_vault_acc, isSigner: false, isWritable: true },
      { pubkey: from_acc, isSigner: false, isWritable: true },
      { pubkey: manager_acc, isSigner: true, isWritable: false },
      { pubkey: clock_acc, isSigner: false, isWritable: false },
      { pubkey: token_program_acc, isSigner: false, isWritable: false },
    ];
    const trxi = new TransactionInstruction({ keys, programId, data });
    return trxi;
  }

  static claimReward(
    registrar_acc,
    member_acc,
    beneficiary_acc,
    balance_vault_acc,
    vendor_acc,
    vendor_pda_acc,
    vendor_vault_acc,
    reward_receiver_acc,
    clock_acc,
    token_program_acc,
    programId
  ) {
    console.log(
      "claim reward",
      "registrar",
      registrar_acc.toBase58(),
      "member",
      member_acc.toBase58(),
      "beneficiary",
      beneficiary_acc.toBase58(),
      "balance vault",
      balance_vault_acc.toBase58(),
      "vendor",
      vendor_acc.toBase58(),
      "vendor pda",
      vendor_pda_acc.toBase58(),
      "vendor vualt",
      vendor_vault_acc.toBase58(),
      "reward receiver",
      reward_receiver_acc.toBase58(),
      "clock",
      clock_acc.toBase58(),
      "token program",
      token_program_acc.toBase58(),
      "program id",
      programId.toBase58()
    );
    const dataLayout = BufferLayout.struct([BufferLayout.u8("i")]);
    const data = Buffer.alloc(dataLayout.span);
    // token list , a buff and loop
    dataLayout.encode({ i: 10 }, data);
    let keys = [
      { pubkey: registrar_acc, isSigner: false, isWritable: false },
      { pubkey: member_acc, isSigner: false, isWritable: true },
      { pubkey: beneficiary_acc, isSigner: true, isWritable: false },
      { pubkey: balance_vault_acc, isSigner: false, isWritable: false },
      { pubkey: vendor_acc, isSigner: false, isWritable: true },
      { pubkey: vendor_pda_acc, isSigner: false, isWritable: false },
      { pubkey: vendor_vault_acc, isSigner: false, isWritable: true },
      { pubkey: reward_receiver_acc, isSigner: false, isWritable: true },
      { pubkey: clock_acc, isSigner: false, isWritable: false },
      { pubkey: token_program_acc, isSigner: false, isWritable: false },
    ];
    const trxi = new TransactionInstruction({ keys, programId, data });
    return trxi;
  }

  static expire_reward(
    registrar_acc,
    vendor_acc,
    vendor_vault_acc,
    vendor_pda_acc,
    expiry_receiver_acc,
    token_program_acc,
    clock_acc,
    programId
  ) {
    const dataLayout = BufferLayout.struct([BufferLayout.u8("i")]);
    const data = Buffer.alloc(dataLayout.span);
    // token list , a buff and loop
    dataLayout.encode({ i: 11 }, data);
    let keys = [
      { pubkey: registrar_acc, isSigner: false, isWritable: false },
      { pubkey: vendor_acc, isSigner: false, isWritable: true },
      { pubkey: vendor_vault_acc, isSigner: false, isWritable: true },
      { pubkey: vendor_pda_acc, isSigner: false, isWritable: false },
      { pubkey: expiry_receiver_acc, isSigner: false, isWritable: true },
      { pubkey: token_program_acc, isSigner: false, isWritable: false },
      { pubkey: clock_acc, isSigner: false, isWritable: false },
    ];
    const trxi = new TransactionInstruction({
      keys,
      programId: programId,
      data,
    });
    return trxi;
  }
}
