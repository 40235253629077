<template>
  <el-dialog :visible.sync="show" :append-to-body="true">
    <template slot="title">
      <div class="title">Unstake</div>
      <div class="title2">Unstake your SYP.</div>
    </template>
    <div class="amount">
      <div class="title">
        Amount
        <span class="asset">SYP</span>
      </div>
      <el-input class="input" v-model="amount">
        <el-button slot="suffix" class="max" @click="max">MAX</el-button>
      </el-input>
    </div>
    <div class="balance">
      <span>Staked Balance</span>
      <span class="asset">SYP</span>
      {{ balance.toFixed(3) }}
    </div>
    <el-row :gutter="15" class="btn-box">
      <el-col :span="8" :offset="12">
        <el-button
          class="btn btn0 btn-c"
          @click="unstake()"
          v-loading="loading"
          >Unstake Now</el-button
        >
      </el-col>
      <el-col :span="4">
        <el-button
          circle
          class="refresh ref0"
          @click="refresh()"
          :class="{ loading: refreshing }"
        ></el-button>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  name: "UnstakeDialog",
  data() {
    return {
      show: false,
      amount: 0.0,
    };
  },
  props: {
    product: Object,
    balance: Number,
    refreshing: Boolean,
    loading: Boolean,
  },
  methods: {
    unstake() {
      if (!(this.amount > 0)) {
        this.$notify({
          title: "Error",
          message: "Invalid amount.",
          type: "warning",
        });
        return;
      }
      if (!(this.balance >= this.amount)) {
        this.$notify({
          title: "Error",
          message: "Balance not enough.",
          type: "warning",
        });
        return;
      }
      let amount = this.amount;
      let type = this.product.type;
      this.$emit("unstake", { type, amount });
    },
    refresh() {
      this.$emit("refresh");
    },
    max() {
      this.amount = this.balance;
    },
  },
  computed: {
    reward() {
      return ((this.amount * this.product.apy) / 365) * this.product.type;
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 22px;
  color: white;
  text-transform: uppercase;
}
.title2 {
  font-size: 10px;
  color: white;
}
.asset {
  font-size: 12px;
  color: white;
  background-color: #1b1c38;
  display: inline-block;
  padding: 3px 7px;
  border-radius: 3px;
}
.amount {
  .title {
    font-weight: bold;
    text-transform: none;
  }
  .input {
    margin-top: 15px;
    .el-input__inner {
      font-size: 16px;
      color: white;
      line-height: 40px;
      background-color: #1b1c3a;
    }
    .max {
      background-color: transparent;
      font-size: 12px;
      padding: 0 20px;
      line-height: 40px;
      border: none;
      color: gray;
    }
  }
}
.balance {
  margin-top: 15px;
  color: white;
  & > span {
    font-weight: bold;
  }
}
.btn-box {
  margin-top: 10px;
  .text {
    font-size: 16px;
    color: white;
    white-space: nowrap;
    & > span {
      font-weight: bold;
    }
  }
  .btn {
    font-size: 16px;
    padding: 12px 0;
    width: 100%;
    text-align: center;
    text-transform: none !important;
  }
  .refresh {
    height: 35px;
    width: 100%;
    background-color: transparent;
    background-position: center;
    background-image: url("../assets/img/refresh.png");
    background-size: contain;
    background-repeat: no-repeat;
    border: none;
    &.loading {
      animation: 3s linear 0;
      animation-name: refreshing;
      animation-iteration-count: infinite;
      @keyframes refreshing {
        0% {
          transform: rotate(0turn);
        }
        50% {
          transform: rotate(0.5turn);
        }
        100% {
          transform: rotate(1turn);
        }
      }
    }
  }
}
.tips {
  border-top: 2px solid #1b1c3a;
  margin-top: 20px;
  overflow: hidden;
  display: flex;
  .tipsImg {
    height: 100%;
    margin-right: 10px;
    margin-top: 10px;
  }
  img {
    width: 35px;
    height: 30px;
  }
  .tipsInner {
    margin-top: 10px;
    p {
      font-size: 14px;
      font-weight: normal;
      color: white;
      margin-bottom: 10px;
      line-height: 20px;
      word-break: normal;
    }
  }
}

@media (max-width: 980px) {
  .title {
    font-size: 18px;
    font-weight: normal;
    line-height: 25px;
  }
  .amount {
    .title {
      font-size: 14px;
      font-weight: normal;
    }
    .input {
      .el-input__inner {
        width: 305px;
        height: 35px;
        padding-right: 0px;
      }
      .max {
        width: 75px;
        height: 30px;
        background: #2169d3;
        line-height: 30px;
        border: 1px solid #2169d3;
        border-radius: 5px;
        position: absolute;
        right: -16px;
        top: 2px;
      }
    }
  }
  .balance {
    & > span {
      font-weight: normal;
    }
  }
  .btn-box {
    .text {
      font-size: 14px;
      & > span {
        font-weight: normal;
      }
    }
    .btn {
      padding: 0;
      font-size: 14px;
      font-weight: normal !important;
      letter-spacing: 0px;
      margin-left: 10px;
      height: 30px;
    }
    .btn0 {
      width: 153px;
      position: absolute;
      left: 70px;
    }
    .refresh {
      height: 30px;
    }
    .ref0 {
      position: relative;
      left: 235px;
    }
  }
}
</style>
