import { PublicKey } from "@solana/web3.js";
import * as BufferLayout from "buffer-layout";

const PercenMul = 10 ** 6;

// buffer layout
export const MemberDataLayout = BufferLayout.struct([
  BufferLayout.u8("nonce"),
  BufferLayout.u32("rewardCursor"),
  BufferLayout.ns64("lastStakeTs"),
  BufferLayout.blob(32, "registrar"),
  BufferLayout.blob(32, "beneficiary"),
  BufferLayout.blob(32, "balance_vault"),
]);

// function
export async function getMemberData(connection, memberKey) {
  // use account
  let memberAcc = new PublicKey(memberKey);
  // get data
  let memberData = await connection.getAccountInfo(memberAcc);
  if (memberData) {
    let temp = MemberDataLayout.decode(memberData.data);
    temp["memberKey"] = memberAcc.toBase58();
    // return { code: 1, msg: 'get member data ok', data: handleKey(temp) };
    return handleKey(temp);
  } else {
      // return { code: 0, msg: "member is null", data: null };
      return;
  }
}

function handleKey(data) {
  for (let key in data) {
    if (data[key].length == 32) {
      let pubkey = new PublicKey(data[key]);
      data[key] = pubkey.toBase58();
    }
  }
  return data;
}
